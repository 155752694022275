import React from "react"
import { css } from "theme-ui"
import Header from "../gatsby-theme-blog/components/header"
import FiltersContent from "../components//content/Filters"
import SiteFooter from "../components/SiteFooter"
// https://www.gatsbyjs.com/docs/how-to/styling/using-web-fonts/ - https://github.com/fontsource/fontsource
import "@fontsource/open-sans" // Defaults to weight 400 with all styles included.
import "@fontsource/open-sans/300.css" // Weight 300 with all styles included.


export default function filters() {
  return  <div css={css({fontFamily: 'Open Sans'})}>
            <Header />
            <FiltersContent />
            <SiteFooter />
          </div>
}
