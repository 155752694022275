import React from "react"
import { css, Grid, Box } from "theme-ui"
// import { Heading, Checkbox, Label, Flex } from "theme-ui"
import { YouTube } from "mdx-embed"
// import LogoImg from "../../content/assets/logo.png"
// import LeftNav from "./leftNav.js"
// import RightNav from "./rightNav.js"
import { SectionHeading, SectionImage, SectionParagraph, SectionSubHeading, SectionImageCaption } from "../SectionElements.js"
import { OutboundLink } from "gatsby-plugin-gtag"


export default function HowToUseContent() {

  return (
    <div css={css({fontWeight: 300,})}>
		
      <div css={css({textAlign: 'center'})}>
        <h1 css={css({paddingTop: 3, paddingBottom: 1, margin: 0, fontWeight: 300, textTransform: 'uppercase'})}>
          The protection of a filter
        </h1>
        <h3 css={css({paddingBottom: 4, paddingTop: 3, marginTop: 0, fontWeight: 300,})}>
          How to select an appropriate filter 
        </h3>
      </div>

			{/* CM2.5 */}			
			<div className="row">
				<div className="col-12 col-md-5 col-xl-5 m-3">
		      <img src="/CM2.5_600.JPG" className="img-fluid" alt="Responsive image" />
		    </div>
				<div className="col-12 col-md-6 col-xl-6 m-3">
					<h5>CM2.5</h5>
					<p>This filter has excellent breathability and good protection.</p>
					<p>Recommended for healthy and vaccinated individuals.</p>
					<p>Use it in indoor public venues or outdoor crowded spaces for up to a few hours on end.</p>
				</div>
			</div>	
			{/* CM99 */}				
			<div className="row">
				<div className="col-12 col-md-5 col-xl-5 m-3">
		      <img src="/CM99_600.JPG" className="img-fluid" alt="Responsive image" />
		    </div>
				<div className="col-12 col-md-6 col-xl-6 m-3">
					<h5>CM99</h5>
					<p>Better than a KN95 mask, this filter gives you the best protection at the expense of some breathability.</p>
					<p>Recommended for both non vaccinated and vaccinated individuals in the following cases:</p>
          <ul>
    					<li>Non vaccinated, immunocompromised and individuals with conditions that weaken immunity such as diabetes 
    					ahould use this filter.</li>
    					<li>Vaccinated individuals working in a customer facing role should use this filetr because they are in contact 
    					with strangers all day and the odds of being in contact with an infected person are high.</li>
          </ul>
          <p>Each production run of 99% efficient filter material is tested on particles ranging from 0.3 um to 10.0 um. 
            All production efficiency exceeds 99% on 0.3 um particulate matter. Tested at ~32 L/min airflow.</p>
          <ul>
  					<li>Efficiency on 0.3 um particles: exceeds 99% </li>
            <li>Efficiency on 0.5 um particles: exceeds 99% </li>
            <li>Efficiency on 1.0 um particles: exceeds 99% </li>
            <li>Efficiency on 3.0 um particles: exceeds 99.9%</li>
          </ul>
				</div>
			</div>	
      
	  {/* Filtration efficiency  */}
	  <div className="row">
	    <div className="col-12 col-md-5 col-xl-5 m-3">
		    <div>
					<p>Filtration efficiency of 1.0 to 3.0 micron particles - PM2.5 type filters:</p>
					<ul>
						<li>CM2.5: 85%</li>
						<li>CM99: 99.9%</li>
					</ul>
		    </div>
        <br/>
        <div>
					<p>Filtration efficiency of 0.3 micron particles - N95 masks filter 95%:</p>
					<ul>
						<li>CM2.5: 50%</li>
						<li>CM99: 99%</li>
					</ul>
        </div>
			</div>
	    <div className="col-12 col-md-6 col-xl-6 m-3">
				<p>While the virus is about 0.1 micron in size “There is never a naked virus floating in the air or released by people,” 
        according to Linsey Marr, a professor of civil and environmental engineering at Virginia Tech who specializes in 
        airborne transmission of viruses in an interview to
		    {/* 2021-07-30 certificate invalid
        <OutboundLink css={css({
		      fontWeight: 300,
		      textDecoration: `none`,
		      ":hover": {
		        textDecoration: `underline`,
		      },
		    })}
					href="https://www.air.cee.vt.edu/" target="_blank"
				> Linsey Marr</OutboundLink>, a professor of civil and environmental engineering at Virginia Tech who specializes in airborne 					transmission of viruses in an interview to */}
		    <OutboundLink css={css({
		      fontWeight: 300,
		      textDecoration: `none`,
		      ":hover": {
		        textDecoration: `underline`,
		      },
		    })}
					href="https://www.usatoday.com/story/news/factcheck/2020/06/11/fact-check-n-95-filters-not-too-large-stop-covid-19-particles/5343537002/" target="_blank"
				> USA Today.</OutboundLink></p>
				<p>This is why the PM2.5 filters are a good protection against the virus although filtering for even smaller 
					particles is better.</p>
				<p>The filter you should use depends on your health condition and the amount of time you are 
					potentially exposed to the virus. If you are only shopping for 20 minutes in a store and you are healthy 
					you can use the CM2.5 but if you are working as a cashier in that store you should go for the CM99.</p>
        <p>A filter lasts about one week if used for one ot two hours a day. If used in a customer facing role we recommend to replace it daily.</p>
	    </div>
		</div>
					
		</div>
  )
}